import "./Stepper.css";
import classNames from "classnames";
import useCheckScreenSize from "../../utils/CheckScreenSize";
import { Grid } from "@mui/material";
import { stepperArray } from "../../../types/steps";

export const Stepper: React.FC<{ current: number }> = ({ current }) => {
  const isMobile = useCheckScreenSize({ breakpoint: 834 });

  return (
    <div>
      {isMobile && (
        <>
          <div className="mobileHeader">
            Portfolio Manager Web <br /> Services Data Authorization
          </div>
          <div style={{ fontWeight: "bold", marginBottom: "15px" }}>
            Step{" "}
            {
              stepperArray.find(({ displayOrder }) => displayOrder === current)
                ?.labelId
            }
          </div>
        </>
      )}
      <Grid container spacing={0}>
        <Grid item xl={11} lg={11} md={11} xs={12}>
          <div className={classNames(isMobile && "row", isMobile && "center")}>
            {stepperArray.map((step) => {
              const label = step.labelId;
              const key = step.displayOrder;
              return (
                <div key={key}>
                  {!isMobile ? (
                    <>
                      <Grid
                        container
                        spacing={0}
                        aria-current={current === step.displayOrder && "step"}
                      >
                        <Grid item xl={1} lg={1} md={1} sm={1}>
                          {current === step.displayOrder && (
                            <div className="current" aria-label="current">
                              <div className="currentMiddle"></div>
                            </div>
                          )}
                          {step.displayOrder > 0 &&
                            current < step.displayOrder && (
                              <div className="grayCircle"></div>
                            )}
                          {step.displayOrder > 0 &&
                            current > step.displayOrder && (
                              <div className="circle"></div>
                            )}
                        </Grid>
                        <Grid item xl={11} lg={11} md={11} sm={11}>
                          {current === step.displayOrder ? (
                            <>
                              {" "}
                              <div
                                className={classNames(
                                  current === step.displayOrder
                                    ? "stepText"
                                    : "stepTextNext"
                                )}
                              >
                                {label}
                              </div>
                            </>
                          ) : (
                            <div
                              className={classNames(
                                current === step.displayOrder
                                  ? "stepText"
                                  : "stepTextNext"
                              )}
                            >
                              {label}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      {key <= stepperArray.length - 1 && (
                        <div
                          className={classNames(
                            "line",
                            current > step.displayOrder && "blue"
                          )}
                        />
                      )}
                    </>
                  ) : (
                    <div>
                      <div className={classNames("row", "center")}>
                        {current === step.displayOrder && (
                          <div className="current" aria-label="current">
                            <div
                              className="currentMiddle"
                              aria-current="step"
                            ></div>
                          </div>
                        )}
                        {step.displayOrder > 0 &&
                          current < step.displayOrder && (
                            <div className="grayCircle"></div>
                          )}
                        {step.displayOrder > 0 &&
                          current > step.displayOrder && (
                            <div className="circle"></div>
                          )}
                        {key <= stepperArray.length - 1 && (
                          <div
                            className={classNames(
                              "mobileLine",
                              current > step.displayOrder ? "blue" : "gray"
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Grid>
        <br />
      </Grid>
      {isMobile && <div style={{ marginBottom: "30px" }}></div>}
    </div>
  );
};
