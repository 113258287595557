import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App, UnderConstruction } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if ((process.env.REACT_APP_OOS as string) === "true") {
  root.render(<UnderConstruction />);
} else {
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

reportWebVitals();
