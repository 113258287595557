import Alert from "@mui/material/Alert";
import { ReactComponent as AlertIcon } from "../../icons/Alert.svg";

interface AlertBannerProps {
  text: string;
}
export default function AlertBanner(props: AlertBannerProps) {
  const { text } = props;
  return (
    <Alert
      icon={
        <div>
          <AlertIcon />
        </div>
      }
      sx={{
        background: "#F7D8D8",
        color: "black",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
        fontWeight: "bold",
        marginTop: "10px",
        marginBottom: "15px",
      }}
    >
      <div style={{ marginTop: "4px" }}>{text}</div>
    </Alert>
  );
}
