import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as CheckboxAllIcon } from "../../icons/CheckboxAll.svg";
import { ReactComponent as DeselectIcon } from "../../icons/Deselect.svg";

export interface PremiseAddressObj {
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  state?: string;
}
export interface ServiceAgreementData {
  serviceAgreementID: string;
  serviceAgreementType: string;
  meterBadgeNumber: number;
  premiseAddress: PremiseAddressObj;
}

export interface BuildingData {
  buildingID: number;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: number;
  name: string;
  FIRSTNAME: string;
  LASTNAME: string;
  premiseID: number;
}

export function createServiceAgreementTableData(
  serviceAgreementID: string,
  serviceAgreementType: string,
  meterBadgeNumber: number,
  premiseAddress: PremiseAddressObj
) {
  return {
    serviceAgreementID,
    serviceAgreementType,
    meterBadgeNumber,
    premiseAddress,
  };
}

export function createBuildingTableData(
  buildingID: number,
  address1: string,
  address2?: string,
  city?: string,
  state?: string,
  zip?: number,
  firstName?: string,
  lastName?: string,
  premiseID?: number
) {
  return {
    buildingID,
    address1,
    address2,
    city,
    state,
    zip,
    firstName,
    lastName,
    premiseID,
  };
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F4F5F6",
  },

  "& .MuiTableCell-root": {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
}));

export interface ServiceAgreementHeadCell {
  id: keyof ServiceAgreementData;
  label: string;
  numeric: boolean;
}

export const serviceAgreementHeadCells: readonly ServiceAgreementHeadCell[] = [
  {
    id: "serviceAgreementID",
    numeric: false,
    label: "Service Agreement ID(s)",
  },
  {
    id: "serviceAgreementType",
    numeric: true,
    label: "Service Type",
  },
  {
    id: "meterBadgeNumber",
    numeric: true,
    label: "Meter",
  },
  {
    id: "premiseAddress",
    numeric: true,
    label: "Service Address",
  },
];

export interface BuildingHeadCell {
  id: keyof BuildingData;
  label: string;
  numeric: boolean;
}
export const buildingHeadCells: readonly BuildingHeadCell[] = [
  {
    id: "buildingID",
    numeric: true,
    label: "Building ID(s)",
  },
  {
    id: "address1",
    numeric: false,
    label: "Address",
  },
  {
    id: "name",
    numeric: false,
    label: "Name",
  },
];

export const handleSelectAllClick = (
  event: React.ChangeEvent<HTMLInputElement>,
  data: any,
  formData: any,
  setFormData: any,
  selected: string
) => {
  if (event.target.checked) {
    const newSelected = data.map((n: any) => n);
    setFormData({ ...formData, [selected]: newSelected });
    return;
  }
  setFormData({ ...formData, [selected]: [] });
};

export const handleClick = (
  event: React.MouseEvent<unknown>,
  name: object,
  formData: any,
  setFormData: any,
  selected: string
) => {
  const selectedIndex = formData[selected].indexOf(name);
  let newSelected: readonly object[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(formData[selected], name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(formData[selected].slice(1));
  } else if (selectedIndex === formData[selected].length - 1) {
    newSelected = newSelected.concat(formData[selected].slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      formData[selected].slice(0, selectedIndex),
      formData[selected].slice(selectedIndex + 1)
    );
  }
  setFormData({ ...formData, [selected]: newSelected });
};

export interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  value?: any;
  checkbox?: boolean;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount, value } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{
            fontWeight: 700,
            backgroundColor: "#0C7DBB",
            color: "white",
            fontSize: "20px",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderBottom: "1px solid",
          }}
        >
          <Checkbox
            checkedIcon={<CheckboxAllIcon style={{ width: "80%" }} />}
            indeterminateIcon={<DeselectIcon style={{ width: "80%" }} />}
            sx={{ color: "white" }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
          {value}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export const serviceType = (code: string) => {
  if (code === "E") {
    return "Electric";
  } else if (code === "G") {
    return "Gas";
  } else return "";
};
