import {UserData} from '../types/types'

export const INITIAL_DATA: UserData ={
    accountID: "",
    phoneNumber: "",
    meterID: "",
    premiseID: "",
    authorizecheck: "AUTHORIZATION",
    email: "",
    selectedServiceAgreements: [],
    selectedBuildings: [],
  }