import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import classNames from "classnames";
import useCheckScreenSize from "./utils/CheckScreenSize";
import { ReactComponent as CheckboxIcon } from "./icons/Checkbox.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { TableServiceAgreements } from "./assets/Tables/TableServiceAgreements";
import { TableBuilding } from "./assets/Tables/TableBuilding";
import MobileTable from "./assets/Tables/MobileTable";
import MobileTableBuilding from "./assets/Tables/MobileTableBuilding";
import { INITIAL_DATA } from "../config/initial-data";
import AlertBanner from "./assets/Alert/AlertBanner";
import "./styles.css";

interface Props {
  formData: any;
  setFormData: any;
}
const Review: React.FC<Props> = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const isMobile = useCheckScreenSize({ breakpoint: 600 });
  const [processingError, setProcessingError] = useState(false);
  const [systemError, setSystemError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const handleAgree = (e: any) => {
    setAgree(e.target.checked);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const requestBody = {
    customerFullName: formData.fullName,
    authorizecheck: formData.authorizecheck,
    selectedBuildings: formData.selectedBuildings,
    selectedServiceAgreements: formData.selectedServiceAgreements,
    email: formData.email,
  };
  async function call() {
    const response = await fetch(
      (process.env.REACT_APP_API_URL as string) + "agreements",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    if (
      response.status === 500 ||
      response.status === 502 ||
      response.status === 403
    ) {
      setLoading(false);
      setSystemError(true);
    } else if (response.status === 400) {
      setLoading(false);
      setProcessingError(true);
    }
    let data = await response.json();
    return data;
  }

  const onSubmit = (e: any) => {
    setSystemError(false);
    setProcessingError(false);
    async function callApi() {
      const response = await call();
      if (response && !response.error) {
        setLoading(false);
        navigate("../confirmation");
      }
    }
    if (!errors.length && agree) {
      setLoading(true);
      callApi();
    }
  };

  const onCancel = () => {
    setFormData(INITIAL_DATA);
    navigate("/");
  };

  return (
    <>
      <div className="description">
        Please review and submit this form to authorize or revoke authorization
        to disclose your electricity and gas usage information to the designated
        Third Party.
      </div>
      <div className="description">
        The Identified Third Party(s) are appointed with the authority to obtain
        from PG&E the electricity and gas usage for my PG&E account listed below
        for the purpose of calculating the overall energy usage of the building
        in which I am receiving electricity and gas service, and to share the
        usage with the U.S Environmental Protection Agency to assist in the
        calculation.
      </div>
      {formData.authorizecheck === "AUTHORIZATION" ? (
        <>
          <h2 className="subheader">Authorization</h2>
          <div className="description" style={{ marginBottom: "20px" }}>
            I, <b>{formData.fullName!}</b>, Authorize the disclosure of energy
            usage and cost of service addresses identified below.
          </div>
        </>
      ) : (
        <>
          <h2 className="subheader"> Revocation</h2>
          <div className="description" style={{ marginBottom: "15px" }}>
            I, <b>{formData.fullName!}</b>, Revoke all authorization of
            disclosure of energy usage and cost of all service addresses
            identified below.
          </div>
          <div style={{ marginBottom: "20px" }}>
            Account ID: {formData.accountID!}
          </div>
        </>
      )}
      <h3 className="subheader">Service Agreement(s)</h3>
      {formData.selectedServiceAgreements.length > 0 &&
        (isMobile ? (
          <MobileTable
            checkbox={false}
            formData={formData}
            setFormData={setFormData}
            data={formData.selectedServiceAgreements}
          />
        ) : (
          <TableServiceAgreements
            checkbox={false}
            formData={formData}
            setFormData={setFormData}
            data={formData.selectedServiceAgreements}
          />
        ))}
      {formData.selectedBuildings.length > 0 && (
        <h3 className="subheader" style={{ marginTop: "20px" }}>
          Building(s)
        </h3>
      )}
      {formData.selectedBuildings.length > 0 &&
        (isMobile ? (
          <MobileTableBuilding
            checkbox={false}
            formData={formData}
            setFormData={setFormData}
            data={formData.selectedBuildings}
          />
        ) : (
          <TableBuilding
            checkbox={false}
            formData={formData}
            setFormData={setFormData}
            data={formData.selectedBuildings}
          />
        ))}
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginTop: "20px" }}>
          <h3 className="subheader" id="Agreement">
            *Terms & Conditions
          </h3>
          <div className="description">
            I hereby release, hold harmless, and indemnify PG&E from any
            liability, claims, demands, causes of action, damages, or expenses
            resulting from: 1) any release of information to the building owner,
            building owner agent, landlord, or building operator designated
            above pursuant to this Authorization; 2) the unauthorized use of
            this information by the building owner, building owner agent,
            landlord, or building operator; and 3) from any actions taken by the
            building owner, building owner agent, landlord, or building operator
            pursuant to this Authorization. I understand that PG&E reserves the
            right to verify any authorization request submitted before releasing
            information or taking any action on my behalf. I understand that I
            may cancel this authorization at any time by submitting a written
            request to PG&E. This agreement at all times shall be subject to
            such modifications as the California Public Utilities Commission may
            direct from time to time in the exercise of its jurisdiction.
          </div>
          <FormControlLabel
            aria-labelledby="Agreement agreement_error"
            control={
              <Checkbox
                sx={errors.check && { color: "#e2231a" }}
                checkedIcon={<CheckboxIcon />}
              />
            }
            checked={agree}
            {...register("check", {
              onChange: (e) => handleAgree(e),
              validate: (value) => value === true,
            })}
            label=" *I agree to the Terms & Conditions"
          />
          {errors.check && (
            <div className="errorText" id="agreement_error">
              Error: Please read Terms & Conditions
            </div>
          )}
        </div>
        {(processingError || systemError) && (
          <AlertBanner
            text="Error: There was a problem submitting your authorization. Try
          again later."
          />
        )}
        <div className="emailDescription">
          Please enter your email address to receive a confirmation email.
        </div>
        <div className="formLabel">
          <label htmlFor="email">*Email address</label>
        </div>
        <input
          id="email"
          aria-label="Email"
          aria-required="true"
          aria-describedby="email_error"
          aria-labelledby="email email_error"
          className={classNames(errors.email ? "errorInput" : "input")}
          value={formData.email}
          {...register("email", {
            required: true,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Error: ",
            },
            onChange: (e) =>
              setFormData({ ...formData, email: e.target.value.trim() }),
          })}
        />
        {errors.email && (
          <div className="errorText" id="email_error">
            Error: Enter valid email address
          </div>
        )}
        <br />
        <div className="formLabel">
          <label htmlFor="confirmEmail">*Confirm email address</label>
        </div>
        <input
          id="confirmEmail"
          aria-label="Confirm Email"
          aria-required="true"
          aria-describedby="confirmEmail_error"
          aria-labelledby="confirmEmail confirmEmail_error"
          className={classNames(errors.confirmEmail ? "errorInput" : "input")}
          value={userEmail}
          {...register("confirmEmail", {
            required: true,
            onChange: (e) => {
              setUserEmail(e.target.value.trim());
            },
            validate: (value) => value === formData.email,
          })}
        />
        {errors.confirmEmail && (
          <div className="errorText" id="confirmEmail_error">
            Error: Email addresses must match
          </div>
        )}
        <br /> <br /> <br />
        <button
          className={classNames({ onLoad: loading }, "yellowLoadingButton")}
          type="submit"
          disabled={loading}
        >
          Submit {loading && <CircularProgress color="inherit" size={15} />}
        </button>
      </form>
      <br />
      <br />
      <div className="secondButtonDiv">
        <button
          className="secondButton"
          onClick={() => navigate("../application")}
        >
          Back
        </button>
      </div>
      <div className="secondButtonDiv" style={{ marginTop: "20px" }}>
        <button className="secondButton" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  );
};

export default Review;
