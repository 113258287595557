import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as CheckboxAllIcon } from "../../icons/CheckboxAll.svg";
import {
  handleSelectAllClick,
  handleClick,
  EnhancedTableHead,
  serviceType,
} from "./TableFunctions";
import classNames from "classnames";

interface TableProps {
  checkbox: boolean;
  formData: any;
  setFormData: any;
  data: any;
}
export default function MobileTable(props: TableProps) {
  const { checkbox, formData, setFormData, data } = props;

  const isSelected = (name: object) =>
    formData.selectedServiceAgreements.indexOf(name) !== -1;

  return (
    <>
      <TableContainer
        sx={{ maxWidth: 380 }}
        className={classNames({ tableHeight: checkbox })}
        component={Paper}
      >
        <Table
          stickyHeader
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
            maxWidth: 380,
          }}
          aria-label="Service Agreements Table"
        >
          {checkbox && (
            <EnhancedTableHead
              numSelected={formData.selectedServiceAgreements.length}
              onSelectAllClick={(event) =>
                handleSelectAllClick(
                  event,
                  data,
                  formData,
                  setFormData,
                  "selectedServiceAgreements"
                )
              }
              rowCount={data?.length}
              value={<>Service Agreements</>}
            />
          )}
          <TableBody>
            {data?.map((row: any, index: any) => (
              <>
                <TableRow key={row.serviceAgreementID!}>
                  <TableCell
                    sx={{ paddingLeft: "0px" }}
                    style={{
                      color: "white",
                      backgroundColor: "#0C7DBB",
                    }}
                  >
                    <div className="row">
                      {checkbox ? (
                        <div style={{ marginLeft: "5px" }}>
                          <Checkbox
                            onClick={(event) =>
                              handleClick(
                                event,
                                row,
                                formData,
                                setFormData,
                                "selectedServiceAgreements"
                              )
                            }
                            checkedIcon={
                              <CheckboxAllIcon style={{ width: "80%" }} />
                            }
                            sx={{ color: "white" }}
                            checked={isSelected(row)}
                          />
                        </div>
                      ) : (
                        <div style={{ marginLeft: "20px" }} />
                      )}
                      <div>
                        <div style={{ fontWeight: "700" }}>
                          Service Agreement ID
                        </div>
                        <div>{row.serviceAgreementID!}</div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">
                    <div style={{ fontWeight: "700" }}>Service Type</div>
                    <div>{serviceType(row.serviceTypeCode!)}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    style={{
                      backgroundColor: "#F4F5F6",
                    }}
                  >
                    <div style={{ fontWeight: "700" }}>Meter</div>
                    <div>{row.meterBadgeNumber!}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">
                    <div style={{ fontWeight: "700" }}>Service Address</div>
                    <div>
                      {row.premiseAddress.streetAddress1! + " "}
                      {row.premiseAddress.streetAddress2! + " "}
                      {row.premiseAddress.city!}
                    </div>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
