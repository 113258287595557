import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles.css";
import classNames from "classnames";
import NewTooltip from "./assets/Tooltip/StyledTooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import AlertBanner from "./assets/Alert/AlertBanner";

interface Props {
  formData: any;
  setFormData: any;
  setVerification: (value: string) => void;
  verification: any;
}

const CustomerValidation: React.FC<Props> = ({
  formData,
  setFormData,
  setVerification,
  verification,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [token, setToken] = useState("");
  const [processingError, setProcessingError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [systemError, setSystemError] = useState(false);
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  let captcha: any;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onChange(value: any) {
    setToken(value);
  }

  const handleVerificationOnSubmit = (e: any) => {
    resetField("phoneNumber");
    resetField("meterID");
    formData.meterID = "";
    formData.phoneNumber = "";
    setVerification(e.target.value);
  };

  function phoneFormat(input: any) {
    //returns (###) ###-####
    input = input.replace(/\D/g, "");
    let size = input.length;
    if (size > 0) {
      input = "(" + input;
    }
    if (size > 3) {
      input = input.slice(0, 4) + ") " + input.slice(4, 11);
    }
    if (size > 6) {
      input = input.slice(0, 9) + "-" + input.slice(9);
    }
    return input;
  }
  const checkToken = (e: any) => {
    if (!token) {
      setToken("null");
    }
  };

  const errorMessage = () => {
    if (systemError) {
      return "Error: There was a problem processing your account information. Try again later.";
    } else {
      return "Error: We have encountered a problem processing your request. Verify the information you provided and click next.";
    }
  };
  const requestBody =
    verification === "phoneNumber"
      ? {
          accountID: formData.accountID,
          phoneNumber: formData.phoneNumber,
        }
      : {
          accountID: formData.accountID,
          meterID: formData.meterID,
        };
  async function call() {
    const response = await fetch(
      (process.env.REACT_APP_API_URL as string) + "accounts",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    if (
      response.status === 500 ||
      response.status === 403 ||
      response.status === 502
    ) {
      setLoading(false);
      setSystemError(true);
    } else if (response.status === 400) {
      setLoading(false);
      setProcessingError(true);
    }
    let data = await response.json();

    return data;
  }
  const onSubmit = (e: any) => {
    async function callApi() {
      const response = await call();
      if (response && !response.error) {
        setLoading(false);
        setFormData({
          ...formData,
          fullName: response.fullName,
          serviceAgreementList: response.serviceAgreementList,
          selectedServiceAgreements: [],
          selectedBuildings: [],
          buildingList: null,
        });
        navigate("../application");
      } else {
        setLoading(false);
      }
    }
    if (!errors.length && token.length > 0 && token !== "null") {
      setLoading(true);
      callApi();
    }
  };

  const onCancel = () => {
    window.location.reload();
  };

  return (
    <div>
      <div className="description">
        Use this form to authorize or revoke access to your electricity or gas
        usage data. An authorization will disclose the data to your building
        owner(s) or landlord(s).
      </div>
      {(processingError || systemError) && (
        <AlertBanner text={errorMessage()} />
      )}
      <h2 className="subheader">General account information </h2>
      <div style={{ marginBottom: "30px" }}>*required field</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formLabel">
          <label htmlFor="accountNumber">
            *{t("AccountNumber")}
            <NewTooltip
              id="accountNumber_tooltip"
              title={
                <div id="accountNumber_tooltip">
                  Your account number is in the upper right corner of your
                  monthly PG&E energy statement.
                </div>
              }
            />
          </label>
        </div>{" "}
        <input
          className={classNames(errors.accountID ? "errorInput" : "input")}
          {...register("accountID", {
            required: true,
            pattern: {
              value: /^\d+$/,
              message: "",
            },
            onChange: (e) => {
              setFormData({
                ...formData,
                accountID: e.target.value.replace(/\D/gi, ""),
              });
            },
            validate: (value) => value.length === 10,
          })}
          id="accountNumber"
          aria-describedby="accountNumber_error"
          area-aria-labelledby="accountNumber_error"
          maxLength={10}
          value={formData.accountID}
        />
        {errors.accountID && (
          <div className="errorText" id="accountNumber_error">
            Error: You must enter a 10-digit account number
          </div>
        )}
        <label className="formLabel row" htmlFor="handleVerification">
          *{t("CustomerVerification")}
          <NewTooltip
            id="handleVerification_tooltip"
            title={
              <div id="handleVerification_tooltip">
                Use the format (###) ###-#### to enter any phone number(s)
                linked to your account. <br /> <br />
                Use any meter number(s) linked to your account. Your meter
                number(s) is under “Service Information” in your PG&E energy
                statement.
              </div>
            }
          />
        </label>
        <div className="inputDescription">
          Please provide either a phone number or meter number for verification.
        </div>
        <RadioGroup
          id="handleVerification"
          aria-label="Handle Verification"
          role="radiogroup"
          aria-describedby="handleVerification"
        >
          <FormControlLabel
            aria-label="Phone Number"
            id="phoneNumber"
            sx={{ height: "28px" }}
            name="phoneNumber"
            value="phoneNumber"
            checked={verification === "phoneNumber"}
            onChange={handleVerificationOnSubmit}
            control={<Radio />}
            label="Phone number"
          />
          <FormControlLabel
            aria-label="Meter Number"
            id="meterNumber"
            name="meterID"
            value="meterID"
            checked={verification === "meterID"}
            onChange={handleVerificationOnSubmit}
            control={<Radio />}
            label="Meter number"
          />
        </RadioGroup>
        <br />
        {verification === "phoneNumber" ? (
          <input
            aria-label="Phone Number"
            id="phoneNumber"
            aria-describedby="phoneNumber_error"
            aria-required="true"
            maxLength={14}
            value={phoneFormat(formData[verification])}
            className={classNames(errors.phoneNumber ? "errorInput" : "input")}
            {...register("phoneNumber", {
              required: true,
              pattern: {
                value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: "Error: Please enter a valid phone number",
              },
              onChange: (e) => {
                setFormData({
                  ...formData,
                  [verification]: e.target.value.replace(/\D/g, ""),
                });
              },
            })}
          />
        ) : (
          <input
            aria-label="Meter Number"
            id="meterNumber"
            aria-describedby="meterNumber_error"
            aria-required="true"
            value={formData[verification]}
            className={classNames(errors.meterID ? "errorInput" : "input")}
            {...register("meterID", {
              required: true,
              pattern: {
                value: /^[0-9a-zA-Z]+$/,
                message: "Error: Please enter a valid meter number",
              },
              onChange: (e) => {
                setFormData({
                  ...formData,
                  [verification]: e.target.value.replace(/[^0-9a-zA-Z]/gi, ""),
                });
              },
            })}
          />
        )}
        {verification === "phoneNumber" && errors.phoneNumber && (
          <div className="errorText" id="phoneNumber_error">
            Error: Please enter a valid phone number
          </div>
        )}
        {verification === "meterID" && errors.meterID && (
          <div className="errorText" id="meterNumber_error">
            Error: Please enter a valid meter number
          </div>
        )}
        <div className="formLabel">*{t("Security")}</div>
        <div className="inputDescription">
          Please verify that you are not a robot.
        </div>
        <label htmlFor="g-recaptcha-response" className="visuallyhidden">
          Google Recaptcha
        </label>
        <ReCAPTCHA
          id="g-recaptcha-response"
          aria-label="Google Recaptcha"
          aria-required="true"
          ref={(el) => {
            captcha = el;
          }}
          onExpired={() => {
            captcha.execute();
          }}
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
          onChange={onChange}
          aria-describedby="g-recaptcha-response_error"
        />
        {token === "null" && (
          <div className="errorText" id="g-recaptcha-response_error">
            Error: Verify that you are not a robot
          </div>
        )}
        <br />
        <br />
        <button
          className={classNames({ onLoad: loading }, "yellowLoadingButton")}
          type="submit"
          onClick={checkToken}
          disabled={loading}
        >
          Next {loading && <CircularProgress color="inherit" size={15} />}
        </button>
      </form>
      <br />
      <br />
      <div className="secondButtonDiv">
        <button className="secondButton" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CustomerValidation;
