import { Dialog } from "@mui/material";
import { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { ReactComponent as TimeoutAlertIcon } from "./icons/TimeoutAlert.svg";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { INITIAL_DATA } from "../config/initial-data";

interface Props {
  formData: any;
  setFormData: any;
}
export const IdleTimerContainer: React.FC<Props> = ({
  formData,
  setFormData,
}) => {
  const idleTimerRef = useRef(null);
  const [isActive, setIsActive] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsClose, setModalIsClose] = useState(false);
  const sessionTimeoutRef = useRef<any>(null);
  const navigate = useNavigate();

  const onIdle = () => {
    setModalIsOpen(true);
    sessionTimeoutRef.current = setTimeout(onExit15Min, 600000);
  };

  const onExit15Min = () => {
    clearTimeout(sessionTimeoutRef.current);
    sessionTimeoutRef.current = setTimeout(onExit5Min, 300000);
  };

  const onExit5Min = () => {
    setIsActive(false);
    setModalIsClose(true);
    clearTimeout(sessionTimeoutRef.current);
  };

  const onClickExit = () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    setIsActive(true);
    setFormData(INITIAL_DATA);
    navigate("/");
  };

  const Continue = () => {
    setModalIsOpen(false);
    if (isActive === false) {
      setIsActive(true);
      setFormData(INITIAL_DATA);
      navigate("/");
    }
    clearTimeout(sessionTimeoutRef.current);
  };

  return (
    <div>
      {isActive ? (
        <Dialog
          maxWidth="xs"
          sx={{ marginLeft: "24px" }}
          open={modalIsOpen} //modalIsOpen
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            id="alert-dialog-title"
            className="subheader sessionTimeoutAlert"
          >
            <div style={{ marginBottom: "10px" }}>
              <TimeoutAlertIcon />
            </div>
            Your session will expire in{" "}
            {modalIsOpen ? <b>15 minutes</b> : <b>5 minutes</b>}
          </div>
          <div
            id="alert-dialog-description"
            className="sessionTimeoutDescription"
          >
            For security reasons, your data will be deleted when the session
            expires.
          </div>
          <div className="sessionTimeoutButtons">
            <button
              onClick={Continue}
              className="yellowButton"
              style={{ marginRight: "24px", width: "full" }}
            >
              Stay connected
            </button>
            <div className="secondButtonDiv" style={{ marginTop: "70px" }}>
              <u className="secondButton" onClick={onClickExit}>
                End Session
              </u>
            </div>
          </div>
          <br />
        </Dialog>
      ) : null}

      {!isActive && modalIsClose ? (
        <Dialog
          maxWidth="xs"
          open={modalIsOpen} //modalIsOpen
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            id="alert-dialog-title"
            className="subheader sessionTimeoutAlert"
          >
            <div style={{ marginBottom: "10px" }}>
              <TimeoutAlertIcon />
            </div>
            Your <b>session has expired</b> due to inactivity.
          </div>

          <div id="alert-dialog-description" className="sessionTimeoutDeleted">
            For security reasons, your data was deleted.
          </div>

          <div className="sessionTimeoutOk">
            <button
              onClick={Continue}
              className="yellowButton sessionTimeoutOkButton"
            >
              OK
            </button>

            <br />
          </div>
        </Dialog>
      ) : null}

      {/* 15 minute = 900000 milliseconds */}
      <IdleTimer ref={idleTimerRef} timeout={900000} onIdle={onIdle} />
    </div>
  );
};
