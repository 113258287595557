import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import {
  StyledTableRow,
  buildingHeadCells,
  handleSelectAllClick,
  handleClick,
  EnhancedTableProps,
} from "./TableFunctions";
import { ReactComponent as CheckboxIcon } from "../../icons/Checkbox.svg";
import { ReactComponent as CheckboxAllIcon } from "../../icons/CheckboxAll.svg";
import { ReactComponent as DeselectIcon } from "../../icons/Deselect.svg";
import classNames from "classnames";
import "../../styles.css";
import "./tables.css";

function EnhancedTableHead(props: EnhancedTableProps) {
  const { checkbox, onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        {checkbox && (
          <TableCell
            padding="checkbox"
            style={{
              fontWeight: 700,
              backgroundColor: "#0C7DBB",
            }}
          >
            <Checkbox
              checkedIcon={<CheckboxAllIcon style={{ width: "80%" }} />}
              indeterminateIcon={<DeselectIcon style={{ width: "80%" }} />}
              sx={{ color: "white" }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "Select all buildings",
              }}
            />
            <div className="visuallyhidden">Buildings Table</div>
          </TableCell>
        )}
        {buildingHeadCells.map((headCell) => (
          <TableCell
            style={{
              fontWeight: 700,
              color: "white",
              backgroundColor: "#0C7DBB",
              paddingLeft: "20px",
            }}
            key={headCell.id}
            align={"left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface Props {
  checkbox: boolean;
  formData: any;
  setFormData: any;
  data: any;
}
export const TableBuilding: React.FC<Props> = ({
  checkbox,
  formData,
  setFormData,
  data,
}) => {
  const isSelected = (name: object) =>
    formData.selectedBuildings.indexOf(name) !== -1;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer
            className={classNames({ desktopTableHeight: checkbox })}
          >
            <Table
              stickyHeader
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              aria-label="Buildings Table"
            >
              <EnhancedTableHead
                checkbox={checkbox}
                numSelected={formData.selectedBuildings.length}
                onSelectAllClick={(event) =>
                  handleSelectAllClick(
                    event,
                    data,
                    formData,
                    setFormData,
                    "selectedBuildings"
                  )
                }
                rowCount={data?.length}
              />
              <TableBody>
                {data?.map((row: any, index: any) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow key={row.buildingID!}>
                      {checkbox && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) =>
                              handleClick(
                                event,
                                row,
                                formData,
                                setFormData,
                                "selectedBuildings"
                              )
                            }
                            checkedIcon={
                              <CheckboxIcon style={{ width: "80%" }} />
                            }
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ paddingLeft: "20px" }}
                      >
                        {row.premiseID}
                      </TableCell>
                      <TableCell align="left">
                        {row.address1!} {row.address2!} {row.city!} {row.state!}{" "}
                        {row.zip!}
                      </TableCell>
                      <TableCell align="left">
                        {row.lastName! + ", " + row.firstName!}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};
