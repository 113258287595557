import React, { useEffect } from "react";
import "./styles.css";
import { INITIAL_DATA } from "../config/initial-data";
import { useNavigate } from "react-router-dom";

interface Props {
  formData: any;
  setFormData: any;
}
const Confirmation: React.FC<Props> = ({ formData, setFormData }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const RedirectHome = () => {
    return (
      <>
        <button
          className="secondButton"
          style={{ fontWeight: 700 }}
          onClick={() => {
            setFormData(INITIAL_DATA);
            navigate("/");
          }}
        >
          re-submit this form
        </button>
        .
      </>
    );
  };
  return (
    <>
      <div>
        <div className="subheader">
          Confirmation - Your request has been submitted.
        </div>
        {formData.authorizecheck === "AUTHORIZATION" ? (
          <>
            <div className="description">
              Your selected data <b>will be released</b> to the Authorized Third
              Party account in ENERGY STAR Portfolio Manager.
            </div>
            <div className="description">
              This authorization overrides all previous Portfolio Manager Web
              Services Data authorizations and revocations.
            </div>
            <div className="description">
              To revoke the Authorized Third Party's access to your data, please{" "}
              <RedirectHome />
            </div>
          </>
        ) : (
          <>
            <div className="description">
              Your selected data will <b>no longer be released</b> to the
              Authorized Third Party account in ENERGY STAR Portfolio Manager.
            </div>
            <div className="description">
              This revocation overrides all previous Portfolio Manager Web
              Services Data authorizations and revocations.
            </div>
            <div className="description">
              To authorize release of your data, please <RedirectHome />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Confirmation;
