import React, { useEffect, useState } from "react";

interface BreakpointProps {
  breakpoint: number;
}
const useCheckScreenSize = (props: BreakpointProps) => {
  const { breakpoint } = props;
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= breakpoint;
};

export default useCheckScreenSize;
