import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Stepper } from "./assets/Stepper/Stepper";
import useCheckScreenSize from "./utils/CheckScreenSize";
import Header from "./Header";
import { Footer } from "./Footer";
import Container from "@mui/material/Container";
import { utagView, utagLink } from "./utils/tealium.js";
import { stepperArray } from "../types/steps";
import "./styles.css";

export interface Props {
  component: any;
  current: number;
}
export const Layout: React.FC<Props> = ({ component, current }) => {
  const { t } = useTranslation();
  const isMobile = useCheckScreenSize({ breakpoint: 834 });
  const isLarge = useCheckScreenSize({ breakpoint: 1000 });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("beforeunload", redirectHome);
    return () => {
      window.removeEventListener("beforeunload", redirectHome);
    };
  });

  useEffect(() => {
    const buttonName = current === stepperArray.length ? "submit" : "next";
    const pageTitle = `${t("AppName")} - ${t("step" + current)}`;
    document.title = pageTitle;
    if (current === 1) {
      utagView({ page_name: pageTitle });
    } else {
      const btnTitle = `${t("AppName")} - ${t(
        "step" + (current - 1)
      )} - ${buttonName} button`;
      utagLink({
        form_button: btnTitle,
        form_id: "mainform",
      });
      utagView({ page_name: pageTitle });
    }
  }, [current, t]);

  useEffect(() => {
    if (
      location.state === undefined ||
      location.state == null ||
      location.state === ""
    ) {
      navigate("/");
    }
  }, []);

  const redirectHome = (e: any) => {
    navigate("/");
  };

  return (
    <div>
      <Header />
      <Container maxWidth="lg">
        {!isMobile && (
          <h1 className="header">
            Portfolio Manager Web Services Data Authorization
          </h1>
        )}

        <Grid justifyContent="center" container>
          <Grid item xl={3.5} md={3} style={{ marginTop: "5px" }}>
            <Stepper current={current} />
          </Grid>
          <Grid
            item
            xl={8.5}
            lg={9}
            md={9}
            sm={8}
            xs={12}
            sx={{ marginBottom: "100px" }}
          >
            <div className="row">
              {!isLarge && (
                <div
                  style={{ marginRight: "70px" }}
                  className="divideLine"
                ></div>
              )}
              <div>{component}</div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};
