import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ReactComponent as Search } from "./icons/Search.svg";
import { ReactComponent as Arrow } from "./icons/Arrow.svg";
import { useNavigate } from "react-router-dom";
import { TableServiceAgreements } from "./assets/Tables/TableServiceAgreements";
import { TableBuilding } from "./assets/Tables/TableBuilding";
import useCheckScreenSize from "./utils/CheckScreenSize";
import MobileTable from "./assets/Tables/MobileTable";
import MobileTableBuilding from "./assets/Tables/MobileTableBuilding";
import CircularProgress from "@mui/material/CircularProgress";
import { INITIAL_DATA } from "../config/initial-data";
import AlertBanner from "./assets/Alert/AlertBanner";
import { v4 as uuid } from "uuid";
import "./styles.css";

interface Props {
  formData: any;
  setFormData: any;
}

const ApplicationDetails: React.FC<Props> = ({ formData, setFormData }) => {
  const isMobile = useCheckScreenSize({ breakpoint: 600 });
  const [building, setBuilding] = useState("");
  const [buildingError, setBuildingError] = useState(false);
  const [serviceAgreementError, setServiceAgreementError] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [systemError, setSystemError] = useState(false);
  const [processingError, setProcessingError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const UUID = uuid().toString().replace(/-/g, "");

  const handleAction = (e: any) => {
    setFormData({ ...formData, authorizecheck: e.target.value });
  };

  const requestBody = {
    premiseID: building,
    request_id: UUID,
  };
  async function call() {
    const response = await fetch(
      (process.env.REACT_APP_API_URL as string) + "buildings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    if (
      response.status === 500 ||
      response.status === 502 ||
      response.status === 403 ||
      response.status === 504
    ) {
      setSystemError(true);
    } else if (response.status === 400) {
      setProcessingError(true);
    }
    setLoading(false);
    let data = await response.json();
    return data;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (building.length === 0) {
      setSystemError(false);
      setProcessingError(false);
    }
    async function callApi() {
      const response = await call();
      if (response && !response.error) {
        setLoading(false);
        setSystemError(false);
        setProcessingError(false);
        if (formData.buildingList && formData.buildingList.length > 0) {
          formData.buildingList.push(...response);
          setFormData({
            ...formData,
            buildingList: formData.buildingList,
          });
        } else {
          setFormData({
            ...formData,
            buildingList: response,
          });
        }
      } else {
        setLoading(false);
      }
    }
    if (
      building &&
      building.length === 10 &&
      !formData.buildingList?.find((obj: any) => obj.premiseID === building)
    ) {
      setLoading(true);
      callApi();
    }
  }, [building]);
  const onCancel = () => {
    setFormData(INITIAL_DATA);
    navigate("/");
  };

  useEffect(() => {
    if (formData.authorizecheck === "AUTHORIZATION") {
      if (formData.selectedServiceAgreements.length > 0) {
        setServiceAgreementError(false);
      } else {
        setServiceAgreementError(true);
      }
      if (formData.selectedBuildings.length > 0) {
        setBuildingError(false);
      } else {
        setBuildingError(true);
      }
    } else {
      if (formData.selectedBuildings.length > 0) {
        setFormData({
          ...formData,
          selectedBuildings: [],
        });
      }
      if (formData.selectedServiceAgreements.length > 0) {
        setServiceAgreementError(false);
      } else {
        setServiceAgreementError(true);
      }
    }
  }, [formData, setFormData, buildingError, serviceAgreementError]);

  const errorMessage = () => {
    if (systemError) {
      return "Error: There was a problem searching for your building ID. Please try again later.";
    } else if (processingError) {
      return "Error: Building ID not found. Please type a different ID or try again.";
    } else {
      return "Error: Please select one or more Building ID(s)";
    }
  };
  const onContinue = () => {
    setClickedSubmit(true);
    if (
      (formData.authorizecheck === "AUTHORIZATION" &&
        formData.selectedServiceAgreements.length > 0 &&
        formData.selectedBuildings.length > 0) ||
      (formData.authorizecheck === "REVOCATION" &&
        formData.selectedServiceAgreements.length > 0)
    ) {
      navigate("../review");
    }
  };

  return (
    <>
      <h2 className="subheader">General account information</h2>
      <div style={{ marginBottom: "20px" }} id="userAction">
        *I, <b>{formData.fullName!}</b>, Account ID:{" "}
        <b>{formData.accountID!}</b>
      </div>
      <RadioGroup
        aria-labelledby="userAction"
        name="controlled-radio-buttons-group"
        role="radiogroup"
        aria-describedby="userAction"
      >
        <FormControlLabel
          value="AUTHORIZATION"
          id="userAction"
          checked={formData.authorizecheck === "AUTHORIZATION"}
          onChange={handleAction}
          control={<Radio />}
          label="Authorize disclosure of energy usage and cost of service addresses identified below"
        />
        {isMobile && <div style={{ marginBottom: "15px" }} />}
        <FormControlLabel
          id="userAction"
          value="REVOCATION"
          checked={formData.authorizecheck === "REVOCATION"}
          onChange={handleAction}
          control={<Radio />}
          label="Revoke all authorizations of disclosure of energy usage and cost of service addresses identified below"
        />
      </RadioGroup>
      <div className="formLabel">Service Agreement ID</div>
      <div style={{ marginTop: "10px" }}>
        *Select one or more Service Agreement ID(s)
      </div>
      {serviceAgreementError && clickedSubmit && (
        <AlertBanner text="Error: Please select one or more Service Agreement ID(s)" />
      )}
      <br />
      {isMobile ? (
        <MobileTable
          checkbox={true}
          formData={formData}
          setFormData={setFormData}
          data={formData.serviceAgreementList}
        />
      ) : (
        <TableServiceAgreements
          checkbox={true}
          formData={formData}
          setFormData={setFormData}
          data={formData.serviceAgreementList}
        />
      )}
      <br /> <br />
      {formData.authorizecheck === "AUTHORIZATION" && (
        <>
          <h2 className="subheader">Aggregate building data</h2>
          <div className="description">
            Note: for customers with CCA supply charges, the total energy costs
            (PG&E costs + CCA costs) will be provided for California Assembly
            Bill 802 (AB802), the “Building Energy Use Benchmarking and Public
            Disclosure Program.”
          </div>
          <div className="authPropertyHeader">
            Authorized property owner selection
          </div>
          <div className="description">
            Enter the 10-digit Building ID provided to you by the requester into
            the search box. Then select the requester to whom the data should be
            released to.
          </div>
          <div style={{ display: "flex" }}>
            <a
              className="arrowSecondButton"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pge.com/benchmarking"
            >
              Learn more about aggregated building data
            </a>
            <span style={{ marginTop: "1px", marginLeft: "8px" }}>
              <Arrow />
            </span>
          </div>
          {((buildingError && clickedSubmit) ||
            systemError ||
            processingError) && <AlertBanner text={errorMessage()} />}
          <br />
          <div>*Select up to ten third parties</div>
          <div className="formLabel">
            <label htmlFor="buildingID">Building ID</label>
          </div>
          <input
            id="buildingID"
            maxLength={10}
            className="input"
            value={building}
            onChange={(e: any) => {
              setBuilding(e.target.value.replace(/\D/g, ""));
            }}
            disabled={formData?.buildingList?.length === 10}
          />
          <span className="inputIcon">
            {loading ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              <Search />
            )}
          </span>
          <br /> <br />
          {formData.buildingList ? (
            isMobile ? (
              <MobileTableBuilding
                checkbox={true}
                data={formData.buildingList}
                formData={formData}
                setFormData={setFormData}
              />
            ) : (
              <TableBuilding
                checkbox={true}
                data={formData.buildingList}
                formData={formData}
                setFormData={setFormData}
              />
            )
          ) : (
            <div>*Results will appear in a table below</div>
          )}
        </>
      )}
      <br /> <br />
      <button onClick={onContinue} className="yellowButton">
        Next
      </button>
      <br />
      <br />
      <div className="secondButtonDiv">
        <button className="secondButton" onClick={() => navigate("../")}>
          Back
        </button>
      </div>
      <div className="secondButtonDiv" style={{ marginTop: "20px" }}>
        <button className="secondButton" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  );
};

export default ApplicationDetails;
