import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import CustomerValidation from "./components/CustomerValidation";
import ApplicationDetails from "./components/ApplicationDetails";
import Review from "./components/Review";
import Confirmation from "./components/Confirmation";
import { IdleTimerContainer } from "./components/IdleTimeContainer";
import { stepperArray } from "./types/steps";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Layout } from "./components/Layout";
import "./App.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0C7DBB",
    },
  },
});

export const App: React.FC = () => {
  const [formData, setFormData] = useState({
    accountID: "",
    phoneNumber: "",
    meterID: "",
    premiseID: "",
    authorizecheck: "AUTHORIZATION",
    email: "",
    selectedServiceAgreements: [],
    selectedBuildings: [],
    buildingList: [],
  });
  const [verification, setVerification] = useState("phoneNumber");
  const stepMap: Record<number, any> = {
    1: (
      <CustomerValidation
        formData={formData}
        setFormData={setFormData}
        setVerification={setVerification}
        verification={verification}
      />
    ),
    2: <ApplicationDetails formData={formData} setFormData={setFormData} />,
    3: <Review formData={formData} setFormData={setFormData} />,
    4: <Confirmation formData={formData} setFormData={setFormData} />,
  };

  return (
    <ThemeProvider theme={theme}>
      <IdleTimerContainer formData={formData} setFormData={setFormData} />
      <Routes>
        {stepperArray.map((step: any) => {
          return (
            <Route
              key={step.displayOrder}
              path={step.path}
              element={
                <Layout
                  current={step.displayOrder}
                  component={stepMap[step.displayOrder]}
                />
              }
            />
          );
        })}
      </Routes>
    </ThemeProvider>
  );
};

export const UnderConstruction: React.FC = () => {
  return (
    <div style={{ marginLeft: "50px", marginTop: "30px" }}>
      <h1>Site unavailable</h1>
      <div style={{ marginBottom: "80px" }}>
        We're sorry! PG&E Portfolio Manager Web Services Data Authorization is
        temporarily unavailable. We apologize for any inconvenience.
      </div>
      <div>©{new Date().getFullYear()} Pacific Gas and Electric Company</div>
    </div>
  );
};
