export const en = {
  'AppName': 'Portfolio Manager Web Services Data Authorization',
  'step1': 'General account information',
  'step2': 'Application details',
  'step3': 'Review, agreement, and submission',
  'step4': 'Confirmation',
  AccountNumber: "PG&E account number",
  CustomerVerification: "Customer verification",
  Security: "Security",
};
