export const stepperArray = [
    { labelId: "1. Customer validation", displayOrder: 1, path: "/" },
    {
      labelId: "2. Application details",
      displayOrder: 2,
      path: "/application",
    },
    {
      labelId: "3. Review, agreement & submission",
      displayOrder: 3,
      path: "/review",
    },
    { labelId: "4. Confirmation", displayOrder: 4, path: "/confirmation" },
  ];