  export const utagView = (data) => {
    const timer = setTimeout(() => {
      window?.utag?.view(data);
    }, 300);
    return () => clearTimeout(timer);
  };
  
  export const utagLink = (data) => {
    const timer = setTimeout(() => {
      window?.utag?.link(data);
    }, 300);
    return () => clearTimeout(timer);
  };